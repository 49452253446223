

<script>
import { Line } from 'vue-chartjs'
export default {
    name: "LineChart.vue",
    extends: Line,
    props: {
        chartData: {
            // eslint-disable-next-line vue/require-prop-type-constructor
            type: Array | Object,
            required: false
        },
        chartLabels: {
            type: Array,
            required: true
        },
        data: Array,
    },
    data () {
        return {

            gradient: null,
            gradient2: null,
            gradient3: null,
            gradient4: null,
            gradient5: null,

            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: false,
                            stepSize: 0.1,
                            maxTicksLimit: 10,
                            // suggestedMin: 50,
                            // suggestedMax: 100,
                        },
                        gridLines: {
                            display: true,
                            color: "#333"
                        }
                    // }
                    // ,{
                    //     position: 'right',
                    //     ticks: {
                    //         beginAtZero: false,
                    //         stepSize: 0.1,
                    //         maxTicksLimit: 10,
                    //         // suggestedMin: 50,
                    //         // suggestedMax: 100,
                    //     },
                    //     gridLines: {
                    //         display: true,
                    //         color: "#333"
                    //     }
                    }],
                    xAxes: [ {
                        gridLines: {
                            display: true,
                            color: "#333"
                        }
                    }]
                },
                // legend: {
                //     display: false
                // },
                tooltips: {
                    intersect: false,
                    mode: 'index',
                    position: 'nearest',
                    backgroundColor: '#5a6268'
                },
                responsive: true,
                maintainAspectRatio: false
            }
        }
    },
    mounted () {

        this.gradient = this.$refs.canvas
            .getContext("2d")
            .createLinearGradient(0, 0, 0, 450);
        this.gradient2 = this.$refs.canvas
            .getContext("2d")
            .createLinearGradient(0, 0, 0, 450);
        this.gradient3 = this.$refs.canvas
            .getContext("2d")
            .createLinearGradient(0, 0, 0, 450);
        this.gradient4 = this.$refs.canvas
            .getContext("2d")
            .createLinearGradient(0, 0, 0, 450);
        this.gradient5 = this.$refs.canvas
            .getContext("2d")
            .createLinearGradient(0, 0, 0, 450);

        this.gradient.addColorStop(0, "rgba(220, 53, 69, 0.9)");
        this.gradient.addColorStop(0.5, "rgba(220, 53, 69, 0.25)");
        this.gradient.addColorStop(1, "rgba(220, 35, 69, 0)");

        this.gradient2.addColorStop(0, "rgba(0, 123, 255, 0.5)");
        this.gradient2.addColorStop(0.5, "rgba(0, 123, 255, 0.25)");
        this.gradient2.addColorStop(1, "rgba(0, 123, 255, 0)");

        this.gradient3.addColorStop(0, "rgba(255, 193, 7, 0.25)");
        this.gradient3.addColorStop(0.5, "rgba(255, 193, 7, 0.125)");
        this.gradient3.addColorStop(1, "rgba(255, 193, 7, 0)");

        this.gradient4.addColorStop(0, "rgba(248, 249, 250, 0.25)");
        this.gradient4.addColorStop(0.5, "rgba(248, 249, 250, 0.125)");
        this.gradient4.addColorStop(1, "rgba(248, 249, 7, 250)");

        this.gradient5.addColorStop(0, "rgba(23, 162, 184, 0.25)");
        this.gradient5.addColorStop(0.5, "rgba(23, 162, 184, 0.125)");
        this.gradient5.addColorStop(1, "rgba(23, 162, 184, 0)");17

        this.renderLineChart();
    },

    methods: {
        renderLineChart: function () {
            // this.options.scales.yAxes[0].ticks.suggestedMin = Math.min(...this.chartData[0]) - 0.1;
            // this.options.scales.yAxes[0].ticks.suggestedMax = Math.max(...this.chartData[0]) + 0.1;
            // this.options.scales.yAxes[1].ticks.suggestedMin = Math.min(...this.chartData[1]) - 0.1;
            // this.options.scales.yAxes[1].ticks.suggestedMax = Math.max(...this.chartData[1]) + 0.1;

            this.renderChart({
                labels: this.chartLabels,
                datasets: [


                    {
                        label: 'Temperature',
                        borderColor: '#dc3545',
                        pointBackgroundColor: 'white',
                        borderWidth: 3,
                        pointBorderColor: 'white',
                        // backgroundColor: 'transparent',
                        backgroundColor: this.gradient,
                        data: this.chartData[0]
                    },
                    {
                        label: 'Humidity',
                        borderColor: '#007bff',
                        pointBackgroundColor: 'white',
                        borderWidth: 3,
                        pointBorderColor: 'white',
                        // backgroundColor: 'transparent',
                        backgroundColor: this.gradient2,
                        data: this.chartData[1]
                    },
                    {
                        label: 'Heat Index',
                        borderColor: '#ffc107',
                        pointBackgroundColor: 'white',
                        borderWidth: 3,
                        pointBorderColor: 'white',
                        // backgroundColor: 'transparent',
                        backgroundColor: this.gradient3,
                        data: this.chartData[2]
                    },
                    // {
                    //     label: 'Dew Point',
                    //     borderColor: '#f8f9fa',
                    //     pointBackgroundColor: 'white',
                    //     borderWidth: 3,
                    //     pointBorderColor: 'white',
                    //     // backgroundColor: 'transparent',
                    //     backgroundColor: this.gradient2,
                    //     data: this.chartData[3]
                    // },
                    {
                        label: 'Absolute humidity',
                        borderColor: '#17a2b8',
                        pointBackgroundColor: 'white',
                        borderWidth: 3,
                        pointBorderColor: 'white',
                        // backgroundColor: 'transparent',
                        backgroundColor: this.gradient2,
                        data: this.chartData[4]
                    },
                ]
            }, this.options)
        }
    },
    watch: {
        data: function() {
            // this._chart.destroy();
            //this.renderChart(this.data, this.options);
            this.renderLineChart();
        }
    }
}
</script>

<style scoped>

</style>