<template>
    <h2 id="r-resource-path">
        <!-- COLLECTION -->
        <router-link
            :to="'/' + $route.meta.collectionName"
            :title="'/' + $route.meta.collectionName">
            <button class="btn btn-primary">
                /{{ $route.meta.collectionName }}
            </button>
        </router-link>
        <!-- IF PARAM ID -->
        <router-link
            v-if="$route.params.id !== 'undefined' && $route.meta.type === $restator.CONSTS.TYPES.DOCUMENT && $route.meta.action !== $restator.CONSTS.ACTIONS.ADD"
            :to="'/' + $route.meta.collectionName + '/' + $restator.CONSTS.ACTIONS.ADD"
            :title="$route.meta.collectionName + '/' + $restator.CONSTS.ACTIONS.ADD">
            <button class="btn btn-dark">
                /{{ $restator.CONSTS.ACTIONS.ADD }}
            </button>
        </router-link>
        <router-link
            v-if="typeof $route.params.id !== 'undefined'"
            :to="'/' + $route.meta.collectionName + '/' + $route.params.id"
            :title="'/' + $route.meta.collectionName + '/' + $route.params.id">
            <button class="btn btn-primary">
                /{{ $route.params.id }}
            </button>
        </router-link>

        <!-- ACTION -->
        <router-link
            v-if="$route.meta.type === $restator.CONSTS.TYPES.COLLECTION || ($route.meta.type === $restator.CONSTS.TYPES.DOCUMENT && $route.meta.action === $restator.CONSTS.ACTIONS.ADD)"
            :to="'/' + $route.meta.collectionName + '/' + $restator.CONSTS.ACTIONS.ADD"
            :title="$route.meta.collectionName + '/' + $restator.CONSTS.ACTIONS.ADD">
            <button
                class="btn"
                :class="{'btn-primary': $route.meta.type === $restator.CONSTS.TYPES.COLLECTION && $route.meta.action === $restator.CONSTS.ACTIONS.ADD, 'btn-secondary': $route.meta.type === $restator.CONSTS.TYPES.COLLECTION && $route.meta.action !== $restator.CONSTS.ACTIONS.ADD}">
                /{{ $restator.CONSTS.ACTIONS.ADD }}
            </button>
        </router-link>
        <router-link
            v-if="$route.meta.type === $restator.CONSTS.TYPES.DOCUMENT && ($route.meta.action === $restator.CONSTS.ACTIONS.EDIT)"
            :to="'/' + $route.meta.collectionName + '/' + $route.params.id + '/' + $route.meta.action"
            :title="'/' + $route.meta.collectionName + '/' + $route.params.id + '/' + $route.meta.action">
            <button class="btn btn-primary">
                /{{ $route.meta.action }}
            </button>
        </router-link>
        <router-link
            v-if="$route.meta.type === $restator.CONSTS.TYPES.DOCUMENT && ($route.meta.action === $restator.CONSTS.ACTIONS.SHOW || $route.meta.action === $restator.CONSTS.ACTIONS.DELETE)"
            :to="'/' + $route.meta.collectionName + '/' + $route.params.id + '/' + $restator.CONSTS.ACTIONS.EDIT"
            :title="'/' + $route.meta.collectionName + '/' + $route.params.id + '/' + $restator.CONSTS.ACTIONS.EDIT">
            <button
                class="btn"
                :class="{'btn-secondary': $route.meta.action === $restator.CONSTS.ACTIONS.SHOW, 'btn-dark': $route.meta.action === $restator.CONSTS.ACTIONS.DELETE}">
                /{{ $restator.CONSTS.ACTIONS.EDIT }}
            </button>
        </router-link>
        <router-link
            v-if="$route.meta.type === $restator.CONSTS.TYPES.DOCUMENT && ($route.meta.action === $restator.CONSTS.ACTIONS.DELETE)"
            :to="'/' + $route.meta.collectionName + '/' + $route.params.id + '/' + $route.meta.action"
            :title="'/' + $route.meta.collectionName + '/' + $route.params.id + '/' + $route.meta.action">
            <button class="btn btn-primary">
                /{{ $route.meta.action }}
            </button>
        </router-link>
        <router-link
            v-if="$route.meta.type === $restator.CONSTS.TYPES.DOCUMENT && ($route.meta.action === $restator.CONSTS.ACTIONS.SHOW || $route.meta.action === $restator.CONSTS.ACTIONS.EDIT)"
            :to="'/' + $route.meta.collectionName + '/' + $route.params.id + '/' + $restator.CONSTS.ACTIONS.DELETE"
            :title="'/' + $route.meta.collectionName + '/' + $route.params.id + '/' + $restator.CONSTS.ACTIONS.DELETE">
            <button
                class="btn"
                :class="{'btn-secondary': $route.meta.action === $restator.CONSTS.ACTIONS.SHOW, 'btn-dark': $route.meta.action === $restator.CONSTS.ACTIONS.EDIT}">
                /{{ $restator.CONSTS.ACTIONS.DELETE }}
            </button>
        </router-link>
    </h2>
</template>

<script>
export default {
name: "RBreadcrumb.vue"
}
</script>

<style scoped>
#r-resource-path {
    position: fixed;
    z-index: 1;
    top: 0;
    width: 100%;
    color: white;
    background-color: #111;
    padding: 10px 0 10px 50px;
}

#r-resource-path button {
    margin-left: 4px;
}
</style>