<template>
    <div id="restator">
        <r-app-loading
            v-if="!$restator.store.isApiDefinitionReady"
            :is-api-definition-endpoint-url-provided="isApiDefinitionEndpointUrlProvided"
            :is-api-definition-required-auth="isApiDefinitionRequiredAuth"
            v-on:onMountEndpoint="mountEndpoint"></r-app-loading>

        <div v-if="$restator.store.isApiDefinitionReady" class="container-fluid">
            <!-- Content -->
            <div class="row" style=" height: 100vh;">
                <r-sidebar></r-sidebar>
                <div class="col-12 col-md">
                    <div id="main">
                        <r-resource :resource-meta="resourceMeta" :resource-path="resourcePath"></r-resource>
                    </div>
                </div>
            </div>
        </div>

        <r-debug v-if="$restator.store.appEnv !== 'prod'"></r-debug>
        <!--  // @TODO - use constant instead of string -->
    </div>
</template>

<script>
import RAppLoading from "./RAppLoading.vue"
import RSidebar from "./RSidebar.vue";
import RResource from "./RResource.vue"
import RDebug from "./RDebug.vue"

export default {
    name: "Restator.vue",
    components: {
        RAppLoading,
        RSidebar,
        RResource,
        RDebug,
    },
    data() {
        return {
            isSidebarShown: true,
            resourcePath: "/",
            resourceMeta: {},
            isApiDefinitionEndpointUrlProvided: false,
            isApiDefinitionRequiredAuth: false,
        }
    },
    watch:{
        $route (to, from){
            // Clean the space when caching not required (@TODO - caching feature needs to be developed)
            // @TODO - it should call function in restator.js
            if (from.meta.collectionName !== to.meta.collectionName) {
                this.$restator.store.resources = [];
            }

            let endpointPath = to.path;
            if (to.path.indexOf('/' + this.$restator.CONSTS.ACTIONS.ADD) !== -1) {
                endpointPath = endpointPath.substr(0, to.path.indexOf('/' + this.$restator.CONSTS.ACTIONS.ADD));
            } else if (to.path.indexOf('/' + this.$restator.CONSTS.ACTIONS.EDIT) !== -1) {
                endpointPath = endpointPath.substr(0, to.path.indexOf('/' + this.$restator.CONSTS.ACTIONS.EDIT));
            } else if (to.path.indexOf('/' + this.$restator.CONSTS.ACTIONS.DELETE) !== -1) {
                endpointPath = endpointPath.substr(0, to.path.indexOf('/' + this.$restator.CONSTS.ACTIONS.DELETE));
            }
            this.resourcePath = endpointPath;
            this.resourceMeta = to.meta;
        },
    },
    mounted() {
        this.$root.$on('showToast', this.showToast);
    },
    methods: {
        mountEndpoint(apiDefinitionEndpointUrl, username, password) {
            this.isApiDefinitionEndpointUrlProvided = true;
            this.$restator.setHttpAuth(username, password)
            this.$restator.init(apiDefinitionEndpointUrl)
                // @TODO - probably good place for some actions
                // .then((res) => {
                // })
                .catch(error => {
                    if (error.response.status === 401) {
                        this.isApiDefinitionRequiredAuth = true;
                        this.$root.$emit('showToast', error.message, 'Provided API definition Endpoint URL requires authentication.', 'warning');
                    } else {
                        this.isApiDefinitionEndpointUrlProvided = false;
                        this.$root.$emit('showToast', error.message, 'Please double check that provided API definition Endpoint URL serves valid RAML file.', 'danger');
                    }
                });
        },
        showToast(title, message, variant = 'secondary') {
            this.$bvToast.toast(message, {
                title: title,
                toaster: 'b-toaster-top-right',
                solid: true,
                appendToast: true,
                variant: variant,
                dismissible: true,
            })
        },
    },
}
</script>

<style>

.modal-content {
     background-color: #111 !important;
 }
#modal-scrollable .modal-body {
    padding: 0 !important;
}
.modal-header button {
    color: white !important;
}
.modal-header, .modal-footer {
    border-color: #333 !important;
}
.modal-backdrop {
    opacity: 0.7 !important;
    backdrop-filter: blur(10px);
}

body::-webkit-scrollbar, .modal-body::-webkit-scrollbar {
    width: 14px;
}
body {
    scrollbar-width: thin;
    scrollbar-color: #555 #333;
}
body::-webkit-scrollbar-track, .modal-body::-webkit-scrollbar-track {
    background: #333;
}
body::-webkit-scrollbar-thumb, .modal-body::-webkit-scrollbar-thumb {
    background-color: #555 ;
    border-radius: 6px;
    border: 3px solid #333;
}

body {
    color: white !important;
    background-color: #111 !important;
}

p {
    text-align: justify;
}

button:focus, input:focus, textarea:focus, a.btn:focus, tr:focus, th:focus {
    outline:none !important;
    box-shadow: none !important;
}

#restator {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -moz-osx-font-smoothing: grayscale;
}

.table-dark {
    background-color: #212529 !important;
}
</style>