import VueRouter from 'vue-router'

(function(f) {
    VueRouter.prototype._allRoutes = null
    VueRouter.prototype.getAllRoutes = function allRoutes () {
        return this._allRoutes
    }
    VueRouter.prototype.addRoutes = function addRoutes (routes = []) {
        if (this._allRoutes === null) { // init
            this._allRoutes = this.options.routes
        }
        routes.forEach(function(r) {
            this._allRoutes.push(r)
        }.bind(this))
        return f.apply(this, arguments);
    }
})(VueRouter.prototype.addRoutes)

const router = new VueRouter({
    mode: 'history',
    base: __dirname,
    linkExactActiveClass: 'active',
    routes: [
        { path: '/', name: 'home', meta: {path: '/'} },
    ]
});

export default router;