import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import vueCustomElement from 'vue-custom-element'
import store from './store'
import VueRouter from 'vue-router'
import router from './router.js'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Restator from "./plugins/restator/src/install.js"

Vue.use(Vuex);
Vue.use(vueCustomElement);
Vue.use(VueRouter);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.use(Restator, router);

Vue.config.productionTip = false;

App.store = store;
App.router = router;
Vue.customElement('nano-shop-widget', App);
