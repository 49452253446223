<template>
    <restator></restator>
</template>

<script>
    import Restator from "./plugins/restator/components/Restator.vue"
    export default {
        name: 'App',
        props: {
            appEnv: {
                type: String,
                default: 'prod', // @TODO - use constant instead of string
            },
            path: {
                type: String,
                default: "/"
            },
            useDefaultApiDefinitionEndpointUrl: {
                type: Boolean,
                default: false,
            },
            defaultApiDefinitionEndpointUrl: {
                type: String,
                default: 'https://api.demo.restator.com',
            },
        },
        components: {
            Restator,
        },
        created() {
            if (this.$router.history.current.path !== this.path && this.path !== '/') {
                this.$router.push(this.path);
            }
            this.$restator.store.appEnv = this.appEnv;
            this.$restator.store.useDefaultApiDefinitionEndpointUrl = this.useDefaultApiDefinitionEndpointUrl;
            this.$restator.store.defaultApiDefinitionEndpointUrl = this.defaultApiDefinitionEndpointUrl;
        }
    }
</script>

<style>

</style>
