import { render, staticRenderFns } from "./RResource.vue?vue&type=template&id=c3abeef0&scoped=true&"
import script from "./RResource.vue?vue&type=script&lang=js&"
export * from "./RResource.vue?vue&type=script&lang=js&"
import style0 from "./RResource.vue?vue&type=style&index=0&id=c3abeef0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3abeef0",
  null
  
)

export default component.exports