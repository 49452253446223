<template>
    <div>
        <!-- Sidebar content -->
        <div id="r-sidebar">
            <r-menu-icon :is-sidebar-shown="isSidebarShown" v-on:toggleSidebar="isSidebarShown = !isSidebarShown" ></r-menu-icon>
            <div id="r-sidebar-content" :class="{show: isSidebarShown}">
                <router-link to="/" id="r-home">
                    <h1>Restator Admin</h1>
                </router-link>
                <nav id="r-resource-menu">
                    <ul v-if="$restator.store.isApiDefinitionReady" >
                        <li v-for="endpoint in $restator.getEndpoints()"
                            :key="'endpoint_id_' + endpoint.path.replace(/[^a-zA-z0-9]|[_]/g,'_') + endpoint.action"
                            class="nav">
                            <router-link
                                v-if="endpoint.type === $restator.CONSTS.TYPES.COLLECTION && endpoint.action === $restator.CONSTS.ACTIONS.SHOW"
                                :to="endpoint.path"
                                :title="endpoint.path">
                                <button
                                    class="btn btn-sm"
                                    :class="{'btn-primary': '/' + $route.meta.collectionName === endpoint.path, 'btn-secondary': '/' + $route.meta.collectionName !== endpoint.path}">
                                    <b>{{ endpoint.path }}</b>
                                </button>
                            </router-link>
                            <router-link
                                v-if="endpoint.type === $restator.CONSTS.TYPES.COLLECTION && endpoint.action === $restator.CONSTS.ACTIONS.SHOW"
                                :to="endpoint.path + '/' + $restator.CONSTS.ACTIONS.ADD"
                                :title="endpoint.path + '/' + $restator.CONSTS.ACTIONS.ADD">
                                <button
                                    class="btn  btn-sm"
                                    :class="{'btn-primary': $route.path === endpoint.path + '/' + $restator.CONSTS.ACTIONS.ADD, 'btn-secondary': $route.path !== endpoint.path + '/' + $restator.CONSTS.ACTIONS.ADD}">
                                    /{{ $restator.CONSTS.ACTIONS.ADD }}
                                </button>
                            </router-link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>

        <!-- Sidebar space holder -->
        <div id="r-sidebar-holder" class="col-12 col-md-auto">
            <div class="affix"></div>
        </div>
    </div>
</template>

<script>
import RMenuIcon from "./RMenuIcon.vue"

export default {
    name: "RSidebar.vue",
    components: {
        RMenuIcon
    },
    data() {
        return {
            isSidebarShown: true,
        }
    },
}
</script>

<style scoped>
#r-sidebar {
    width: 0;
    height: 100%;
    overflow-y: scroll;
    z-index: 15;
    position: fixed;
}

#r-sidebar-content::-webkit-scrollbar {
    width: 14px;
}
#r-sidebar-content {
    scrollbar-width: thin;
    scrollbar-color: #555 #333;
}
#r-sidebar-content::-webkit-scrollbar-track {
    background: #333;
}
#r-sidebar-content::-webkit-scrollbar-thumb {
    background-color: #555 ;
    border-radius: 6px;
    border: 3px solid #333;
}

#r-sidebar-content {
    position: fixed;
    width: 350px;
    height: 100%;
    overflow-y: scroll;
    z-index: 1;
    top: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
}

#r-sidebar-holder {
    padding-right: 0px;
    padding-left: 0px;
    height: 100%;
}

#r-sidebar-holder .affix {
    width: 350px;
}

#r-sidebar-content, #r-sidebar-holder {
    transition: left 0.5s;
    left: -350px;
}

#r-sidebar-content.show, #r-sidebar-holder.show {
    left: 0px;
}

@media screen and (max-height: 450px) {
    #r-sidebar-content a {font-size: 18px;}
}

@media (max-width: 1132px) {
    #r-sidebar-content {
        display: none !important;
    }
}

#r-home {
    position: fixed;
    background-color: #111;
    text-decoration: none;
}

#r-home h1 {
    color: white;
    margin: 10px;
    font-size: 2em;
}

#r-resource-menu {
    margin-top: calc(2em + 36px);
}
#r-resource-menu .nav {
    margin-top: 1px;
}
#r-resource-menu .btn-sm {
    line-height: 0.8;
    margin-left: 3px;
}

#r-resource-menu .nav-link {
    padding: .15rem .3rem;
    text-decoration: none;
    font-size: 0.9em;
    color: #a1a1a1;
    display: block;
    transition: 0.3s;
}
</style>