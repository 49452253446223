// import Vue from 'vue'
import constants from './constants.js'
import restator from './restator.js'

const Restator = {};

// The install method will be called with the Vue constructor as
// the first argument, along with possible options
Restator.install = function(Vue, router) {
    restator.plugins.$router = router;

    Vue.prototype.$restator = {
        CONSTS: Object.assign(constants, restator.CONSTS),
        store: Vue.observable(restator.store),
    };
    Object.assign(Vue.prototype.$restator, restator.funcs);
};
// This exports the plugin object.
export default Restator;