<template>
    <b-container>
        <b-row>
            <b-col col xl="8" offset-xl="2">
                <b-form v-if="isApiCallReady" @submit="onSubmit" @reset="onCancel">
                    <b-form-group  v-for="(value, field) in data"
                                   :key="'record_item_' + field"
                                   :id="'input-group-' + field"
                                   :label-for="'input-0' + field"
                                   :label="getLabelFromField(field) + ':'"
                                   :description="formatFieldDataTypeDescription(field)"
                                   label-size="lg">
                                    <!-- @TODO - make following any sense? label-cols-xl="3" label-align-xl="right"> -->

                        <template v-if="type.properties[field].maxLength > 255">
                            <b-form-textarea
                                :id="'input-' + field"
                                v-model="data[field]"
                                rows="4"
                                max-rows="10"
                                lazy-formatter
                                :formatter="formatter"
                                :required="isFieldRequired(field)"
                                :disabled="$route.meta.action === $restator.CONSTS.ACTIONS.SHOW"
                            ></b-form-textarea>
                        </template>

                        <template v-else-if="typeof type.properties[field]['x-foreignTable'] !== 'undefined'">
                            <b-input-group>
                                <!-- @TODO - folowing for M:1 or M:N relations -->
<!--                                <b-form-tags-->
<!--                                    v-model="data[field]"-->
<!--                                    no-outer-focus>-->
<!--                                    <template v-slot="{ tags, tagVariant, removeTag }">-->
<!--                                        <div class="d-inline-block">-->
<!--                                            <b-form-tag-->
<!--                                                v-for="tag in tags"-->
<!--                                                @remove="removeTag(tag)"-->
<!--                                                :key="tag"-->
<!--                                                :title="getForeignData(tag, type.properties[field])"-->
<!--                                                :variant="tagVariant"-->
<!--                                            >{{ getForeignData(tag, type.properties[field]) }}</b-form-tag>-->
<!--                                        </div>-->
<!--                                    </template>-->
<!--                                </b-form-tags>-->

                                <b-form-input
                                    :id="'input-' + field"
                                    :value="foreignData[field]"
                                    :required="isFieldRequired(field)"
                                    type="text"
                                    disabled>
                                </b-form-input>

                                <input v-model="data[field]" type="hidden">

                                <b-input-group-append>
                                    <b-button variant="primary"
                                              @click="modalTargetField = field; $emit('showModal', '/' + type.properties[field]['x-foreignTable'], value);">
                                        <b-icon v-if="$route.meta.action === 'show'" icon="eye-fill "></b-icon>
                                        <b-icon v-else icon="pencil-square"></b-icon>
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </template>

                        <template v-else-if="type.properties[field].type === 'boolean'">
                            <b-form-checkbox
                                size="lg"
                                :id="'input-' + field"
                                v-model="data[field]">
                                {{ data[field] }}
                            </b-form-checkbox>
                        </template>

                        <!-- @TODO - MySQL year should work with just year -->
                        <template v-else-if="type.properties[field].type === 'string' && ['date', 'time', 'date-time-only'].includes(type.properties[field].format)">
                            <b-input-group class="mb-3">
                                <b-form-input
                                    :id="'input-' + field"
                                    v-model="data[field]"
                                    :required="isFieldRequired(field)"
                                    :disabled="$route.meta.action === $restator.CONSTS.ACTIONS.SHOW"
                                    :placeholder="getDateTimePlaceholder(type.properties[field].format)"
                                    type="text">
                                </b-form-input>
                                <b-input-group-append>
                                    <b-form-datepicker v-if="['date', 'date-time-only'].includes(type.properties[field].format)"
                                        :value="value"
                                        @input="onDatepickerChange($event, field, type.properties[field].format)"
                                        :disabled="$route.meta.action === $restator.CONSTS.ACTIONS.SHOW"
                                        :class="type.properties[field].format"
                                        right
                                        button-only
                                        button-variant="primary"
                                        aria-controls="'input-' + field">
                                    </b-form-datepicker>
                                    <!-- @TODO - find better solution than value.substr -->
                                    <b-form-timepicker v-if="['time', 'date-time-only'].includes(type.properties[field].format)"
                                        :value="type.properties[field].format === 'date-time-only' ? value.substr(11) : value"
                                        @input="onTimepickerChange($event, field, type.properties[field].format)"
                                        :disabled="$route.meta.action === $restator.CONSTS.ACTIONS.SHOW"
                                        :hour12="false"
                                        show-seconds
                                        right
                                        button-only
                                        button-variant="primary"
                                        aria-controls="'input-' + field">
                                    </b-form-timepicker>
                                </b-input-group-append>
                            </b-input-group>
                        </template>

                        <template v-else>
                            <!-- required @TODO - based on field properties -->
                            <b-form-input
                                :id="'input-' + field"
                                v-model="data[field]"
                                :type="field.toLowerCase().indexOf('email') !== -1 ? 'email' : 'text'"
                                :required="isFieldRequired(field)"
                                :disabled="$route.meta.action === $restator.CONSTS.ACTIONS.SHOW"
                            ></b-form-input>
                        </template>
                    </b-form-group>

                    <b-row v-if="$route.meta.action !== $restator.CONSTS.ACTIONS.SHOW" class="my-5">
                        <b-col col xl="3" offset-xl="3">
                            <b-button type="reset" block variant="secondary">Cancel</b-button>
                        </b-col>
                        <b-col col xl="3">
                            <b-button type="submit" block variant="primary">Save</b-button>
                        </b-col>
                    </b-row>
                </b-form>

                <div v-else class="text-center text-light my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "RDocumentForm.vue",
        props: {
            resourcePath: String,
            modalSelected: Number,
        },
        data() {
            return {
                isApiCallReady: false,
                data: {},
                foreignData: {},
                type: null,
                modalTargetField: null,
            }
        },
        watch:{
            $route (to){
                this.init(to);
            },
            resourcePath () {
                this.init();
            },
            modalSelected () {
                if (this.modalTargetField !== null) { // @TODO - just quick solution needs to be refactored and checked
                    this.data[this.modalTargetField] = this.modalSelectedIds[0];
                    this.foreignData[this.modalTargetField] = this.getForeignData(this.modalSelectedIds[0], this.type.properties[this.modalTargetField])
                }
            },
        },
        computed: {
            modalSelectedIds:{
                get() { return this.$store.state.modalSelectedIds; },
                set(value) { this.$store.commit('setModalSelectedIds', value) }
            },
        },
        created() {
            this.init(this.$route);
        },
        methods: {
            getDateTimePlaceholder(format) {
                let placeholder = "";
                switch (format) {
                    case 'time':
                        placeholder = "Fill time in HH:mm:ss format or choose a time";
                        break;
                    case 'date':
                        placeholder = "Fill date in YYYY-MM-DD format or choose a date";
                        break;
                    case 'date-time-only':
                        placeholder = "Fill time in YYYY-MM-DD HH:mm:ss format or choose a date and time";
                        break;
                    default:
                        throw "Unknown datetime format!";
                }
                return placeholder;
            },
            onDatepickerChange(date, field, format) {
                let newDate = new Date(date);
                let datetime = new Date(this.data[field]);
                datetime.setFullYear(newDate.getFullYear());
                datetime.setMonth(newDate.getMonth());
                datetime.setDate(newDate.getDate());
                switch (format) {
                    case 'date':
                        this.setDateTimeField(datetime, field, true);
                        break;
                    case 'date-time-only':
                        this.setDateTimeField(datetime, field);
                        break;
                    default:
                        throw "Unknown date format!";
                }
            },
            onTimepickerChange(time, field, format) {
                let [hours, minutes, seconds] = time.split(':');
                let datetime = null;
                switch (format) {
                    case 'time':
                        this.data[field] = time;
                        break;
                    case 'date-time-only':
                        datetime = new Date(this.data[field]);
                        datetime.setHours(hours)
                        datetime.setMinutes(minutes)
                        datetime.setSeconds(seconds)
                        this.setDateTimeField(datetime, field);
                        break;
                    default:
                        throw "Unknown time format!";
                }
            },
            setDateTimeField(datetime, field, dateOnly = false) {
                this.data[field] =
                    datetime.getFullYear()
                    + "-" + ("0" + (datetime.getMonth() + 1)).slice(-2)
                    + "-" + ("0" + datetime.getDate()).slice(-2);
                if (!dateOnly) {
                    this.data[field] +=
                        " " + ("0" + datetime.getHours()).slice(-2)
                        + ":" + ("0" + datetime.getMinutes()).slice(-2)
                        + ":" + ("0" + datetime.getSeconds()).slice(-2);
                }
            },
            formatFieldDataTypeDescription(field) {
                if (this.$restator.store.appEnv !== 'prod') { // @TODO - use constant instead of string
                    return 'Field description: ' + JSON.stringify(this.type.properties[field]) + ', Required: ' + this.isFieldRequired(field);
                }
            },
            isFieldRequired(field) {
                return typeof this.type.required !== 'undefined' && this.type.required.includes(field);
            },
            init(route = null) {
                if (route === null) {
                    route = this.$route;
                }
                let dataTypeName = this.$restator.getDataTypeName(route.meta, 200); // @TODO - 200 based on response and also dataTypeName
                this.type = this.$restator.getType(dataTypeName);

                let endpoint = route.meta;
                let documentPath = '/' + endpoint.collectionName + '/' + this.$route.params.id; // @TODO - use PRIMARY instead of id
                if (endpoint.action === this.$restator.CONSTS.ACTIONS.ADD) {
                    let emptyData = {};
                    let emptyForeignData = {};
                    let dataTypeName = this.$restator.getDataTypeName(endpoint, 200) // @TODO - 200

                    for (const field of Object.keys(this.$restator.getType(dataTypeName).properties)) {
                        emptyData[field] = "";
                        if (typeof this.type.properties[field]['x-default'] !== 'undefined') {
                            emptyData[field] = this.type.properties[field]['x-default'];
                        }
                        if (typeof this.type.properties[field]['x-foreignTable'] !== 'undefined') {
                            emptyForeignData[field] = "";
                        }
                    }
                    this.data = emptyData;
                    this.foreignData = emptyForeignData;
                    this.isApiCallReady = true; // @TODO - it's not api call, just quick fix
                } else if (endpoint.action === this.$restator.CONSTS.ACTIONS.DELETE) {
                    this.isApiCallReady = false;
                    // @TODO - add more readable item (like title, name) to the deletion confirm
                    this.$bvModal.msgBoxConfirm('Are you sure you want to permanently remove item "' + documentPath + '"?', {
                        title: 'Confirm deletion',
                        okVariant: 'danger',
                        okTitle: 'Delete',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then(confirmed => {
                        if (confirmed) {
                            this.$restator.deleteDocument(endpoint.collectionName, this.$route.params.id)
                                .then((res) => {
                                    this.$root.$emit('showToast', res.title, res.message, res.style);
                                    this.$router.push('/' + endpoint.collectionName);
                                })
                                .catch(error => {
                                    this.$root.$emit('showToast', error.title, error.message, error.style);
                                });
                        } else {
                            this.$router.go(-1);
                        }
                    })
                    .catch(error => {
                        this.$root.$emit('showToast', 'Unexpected error during item "' + documentPath + '" deletion!', error.message, 'danger');
                    })
                } else if (endpoint.action === this.$restator.CONSTS.ACTIONS.SHOW || endpoint.action === this.$restator.CONSTS.ACTIONS.EDIT) {
                    this.isApiCallReady = false;
                    this.$restator.getDocument(endpoint.collectionName, this.$route.params.id) // @TODO - use PRIMARY instead of id
                        .then((res) => {
                            this.data = res.data;
                            for (const [field, value] of Object.entries(res.data)) {
                                if (this.type.properties[field].maxLength > 255 && this.$restator.isJson(value)) {
                                    this.data[field] = JSON.stringify(JSON.parse(value), null, "\t");
                                }
                                else if (this.type.properties[field].type === 'boolean') { // @TODO - boolean should be sent as boolean so no evaluation needed / but Restator have to implement type check of data fetched from Mysql and do relevant steps to do that it needs to have knowledge about types which are not accessible in APiDefinition class, so it needs to be implemented or handled in different way like using schemas but this also require additional development as well
                                    this.data[field] = this.$restator.evaluateBoolean(value);
                                }
                            }
                        })
                        .then(() => {
                            let foreignResources = this.$restator.collectForeignResources(endpoint, this.data);
                            let requestCounter = 0;
                            let responseCounter = 0;

                            if (Object.keys(foreignResources).length <= 0) {
                                this.isApiCallReady = true;
                            }

                            for (const foreignResource of Object.values(foreignResources)) {
                                for (const entryToFetch of Object.values(foreignResource.entriesToFetch)) {
                                    requestCounter++;
                                    this.$restator.getDocument(foreignResource.foreignTable, entryToFetch)
                                        .then(() => {
                                            responseCounter++;
                                            if (requestCounter === responseCounter) {
                                                for (const [field, value] of Object.entries(this.data)) {
                                                    if (typeof this.type.properties[field]['x-foreignTable'] !== 'undefined') {
                                                        this.foreignData[field] = this.getForeignData(value, this.type.properties[field])
                                                    }
                                                }
                                                this.isApiCallReady = true;
                                            }
                                        })
                                        .catch(error => {
                                            this.$root.$emit('showToast', error.title, error.message, error.style); // @TODO - error.style should be selected here not in toast builder (simulation: replace "res.data" above with "res.res.data")
                                        });
                                }
                            }
                        })
                        .catch(error => {
                            this.$root.$emit('showToast', error.title, error.message, error.style); // @TODO - error.style should be selected here not in toast builder (simulation: replace "res.data" above with "res.res.data")
                        });
                } else {
                    alert("DOCUMENT SOMETHING WRONG"); // @TODO - create toast
                }
            },
            formatter(value) {
                if (this.$restator.isJson(value)) {
                    return JSON.stringify(JSON.parse(value), null, "\t");
                }
                return value;
            },
            getLabelFromField(field) { // @TODO - move to restator
                field = field.charAt(0).toUpperCase() + field.slice(1);
                return field.replace(/[^a-zA-z0-9]|[_]/g,' ');
            },
            getForeignData(id, fieldProperties) { // @TODO - move to restator
                if (typeof this.$restator.store.resources["/" + fieldProperties['x-foreignTable'] + "/" + id] !== 'undefined') {
                    return this.$restator.store.resources["/" + fieldProperties['x-foreignTable'] + "/" + id][fieldProperties['x-foreignColumnShow']];
                }
                return id;
            },
            onSubmit(evt) {
                evt.preventDefault();
                let endpoint = this.$route.meta;

                for (const [field, value] of Object.entries(this.data)) {
                    if (this.type.properties[field].type === 'boolean') { // @TODO - boolean should be sent as boolean so no evaluation needed / but Restator have to implement type check of data fetched from Mysql and do relevant steps to do that it needs to have knowledge about types which are not accessible in APiDefinition class, so it needs to be implemented or handled in different way like using schemas but this also require additional development as well
                        this.data[field] = (value ? 1 : 0);
                    }
                }

                switch (endpoint.action) {
                    case this.$restator.CONSTS.ACTIONS.ADD:
                        this.$restator.addDocument(this.data, endpoint.collectionName)
                            .then((res) => {
                                this.$root.$emit('showToast', res.title, res.message, res.style);
                                this.$router.push('/' + endpoint.collectionName);
                            })
                            .catch(error => {
                                this.$root.$emit('showToast', error.title, error.message, error.style);
                            });
                        break;
                    case this.$restator.CONSTS.ACTIONS.EDIT:
                        this.$restator.setDocument(this.data, endpoint.collectionName, this.$route.params.id)
                            .then((res) => {
                                this.$root.$emit('showToast', res.title, res.message, res.style);
                                this.$router.push('/' + endpoint.collectionName + '/' + this.$route.params.id);
                            })
                            .catch(error => {
                                this.$root.$emit('showToast', error.title, error.message, error.style);
                            });
                        break;
                    default:
                        throw "Method " + endpoint.method.toUpperCase() + " is not supported by RDocumentForm."; // @TODO - show toast instead
                }
            },
            onCancel(evt) {
                evt.preventDefault();
                this.$router.go(-1);
            }
            // @TODO - reset form mechanism
            //  for add (clear form)
            //  for edit (reset form)
        },
    }
</script>

<style scoped>
/deep/ .form-control:disabled, .form-control[readonly] {
    background-color: #b7b7b7;
}
.custom-control{
    z-index: unset;
}
.date-time-only {
    margin-right: 1px;
}
</style>