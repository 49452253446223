<template>
    <div id="r-menu-icon" :class="{change: isSidebarShown}" @click="$emit('toggleSidebar');">
        <div class="bar bar-top"></div>
        <div class="bar bar-middle"></div>
        <div class="bar bar-bottom"></div>
    </div>
</template>

<script>
export default {
    name: "RMenuIcon.vue",
    props: {
        isSidebarShown: {
            type: Boolean,
            default: true,
        }
    },
}
</script>

<style scoped>
#r-menu-icon {
    position: fixed;
    z-index: 20;
    margin-top: 12px;
    display: inline-block;
    cursor: pointer;
    transition: left 0.5s;
    left: 10px;
    color: white;
}
#r-menu-icon.change {
    left: 290px;
}

.bar {
    width: 35px;
    height: 5px;
    background-color: white;
    margin: 6px 0;
    transition: 0.4s;
}

#r-menu-icon.change .bar.bar-top {
    -webkit-transform: rotate(-45deg) translate(-8px, 6px);
    transform: rotate(-45deg) translate(-8px, 6px);
}

#r-menu-icon.change .bar.bar-middle {
    opacity: 0;
}

#r-menu-icon.change .bar.bar-bottom {
    -webkit-transform: rotate(45deg) translate(-9px, -8px);
    transform: rotate(45deg) translate(-9px, -8px);
}
</style>