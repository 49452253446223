<template>
    <div id="r-app-loading" :style="{ backgroundImage: 'url(' + img + ')' }">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-6">
                    <h1 class="r-loading-dots">
                        GET /user/endpoint-url
                        <template v-if="isApiDefinitionEndpointUrlProvided">
                            200 OK
                            <br>
                            <template v-if="isApiDefinitionRequiredAuth">
                                GET /restator/require-auth
                                200 OK
                                <br>
                                GET /user/auth
                            </template>
                            <template v-else>
                                GET /restator/admin
                            </template>
                        </template>
                        <template v-if="isApiDefinitionEndpointUrlProvided && $restator.store.isApiDefinitionReady">
                            200 OK
                            <br>
                            GET /user
                        </template>
                    </h1>
                </div>
            </div>
        </div>
        <div id="r-loading-quote" class="container fixed-bottom">
            <div class="row justify-content-center">
                <div class="col-xl-6">
                    <p>
                        <b-form-group
                            v-if="!isApiDefinitionEndpointUrlProvided"
                            id="input-group-endpoint"
                            description="Please provide Restator API definition endpoint URL. We'll never share your API definition endpoint URL with anyone else."
                        >
                            <b-input-group class="mt-3">
                                <b-form-input
                                    id="input-endpoint"
                                    v-model="apiDefinitionEndpointUrl"
                                    type="text"
                                    size="sm"
                                    required
                                    placeholder="https://your-api-definition-endpoint.url"
                                ></b-form-input>
                                <template #append>
                                    <b-button
                                        @click="useDefaultEndpoint"
                                        :disabled="apiDefinitionEndpointUrl === $restator.store.defaultApiDefinitionEndpointUrl"
                                        :variant="apiDefinitionEndpointUrl === $restator.store.defaultApiDefinitionEndpointUrl ? 'dark' : 'primary'"
                                        size="sm" block>
                                        Use default endpoint
                                    </b-button>
                                </template>
                            </b-input-group>
                        </b-form-group>

                        <b-form-group
                            v-if="isApiDefinitionRequiredAuth"
                            id="input-group-login"
                            description="Restator API requires authentication. Please provide your credentials.">
                            <b-form-input
                                id="input-username"
                                v-model="username"
                                type="text"
                                size="sm"
                                class="mt-3"
                                required
                                placeholder="Username"
                            ></b-form-input>

                            <b-form-input
                                id="input-password"
                                v-model="password"
                                type="password"
                                size="sm"
                                class="mt-3"
                                required
                                placeholder="Password"
                            ></b-form-input>
                        </b-form-group>

                        <b-button
                            @click="onMountEndpoint"
                            :disabled="apiDefinitionEndpointUrl.length <= 0 || (isApiDefinitionEndpointUrlProvided && !isApiDefinitionRequiredAuth)"
                            :variant="(apiDefinitionEndpointUrl.length > 0 ? 'primary' : 'dark')"
                            size="sm" block>
                            <template v-if="!isApiDefinitionEndpointUrlProvided">
                                Mount endpoint
                            </template>
                            <template v-else-if="isApiDefinitionRequiredAuth">
                                Log In
                            </template>
                            <template v-else>
                                <b-spinner small></b-spinner>
                                Mounting endpoint...
                            </template>
                        </b-button>
                    </p>

                    <blockquote class="blockquote mb-0">
                        <p>{{ loadingQuote.quote }}</p>
                        <footer class="blockquote-footer">{{ loadingQuote.author }}</footer>
                    </blockquote>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "RAppLoading.vue",
    props: {
        resourceMeta: Object,
        resourcePath: String,
        isApiDefinitionEndpointUrlProvided: {
            type: Boolean,
            default: false,
        },
        isApiDefinitionRequiredAuth: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            // useDefaultApiDefinitionEndpointUrl: true, // @TODO - add to config
            // defaultApiDefinitionEndpointUrl: 'https://api.dev.restator.com',
            // defaultApiDefinitionEndpointUrl: 'http://restator.alps-life-kitchen.loc/api/', // @TODO - add to config
            apiDefinitionEndpointUrl: '',
            username: '',
            password: '',
            img : require('./../assets/app-loading.gif'),
            quotes: [
                {
                    quote: 'A lot of companies have chosen to downsize, and maybe that was the right thing for them. We chose a different path. Our belief was that if we kept putting great products in front of customers, they would continue to open their wallets.',
                    author: 'Steve Jobs',
                },{
                    quote: 'I do not fear computers. I fear lack of them.',
                    author: 'Isaac Asimov',
                },{
                    quote: 'Imagination is more important than knowledge. For knowledge is limited, whereas imagination embraces the entire world, stimulating progress, giving birth to evolution.',
                    author: 'Albert Einstein',
                },{
                    quote: 'Never trust a computer you can’t throw out a window.',
                    author: 'Steve Wozniak',
                },{
                    quote: 'Simplicity is about subtracting the obvious and adding the meaningful.',
                    author: 'John Maeda',
                }
            ],
            loadingQuote: null,
        }
    },
    created() {
        this.loadingQuote = this.quotes[Math.floor(Math.random() * this.quotes.length)];

        if (this.$restator.store.useDefaultApiDefinitionEndpointUrl) {
            this.useDefaultEndpoint();
            this.onMountEndpoint();
        }

        window.addEventListener('keyup', this.onKeyUp);
    },
    destroyed() {
        window.removeEventListener('keyup', this.onKeyUp); // detach event listener once component is destroyed
    },
    methods: {
        onMountEndpoint() {
            this.$emit('onMountEndpoint', this.apiDefinitionEndpointUrl, this.username, this.password)
        },
        useDefaultEndpoint() {
            this.apiDefinitionEndpointUrl = this.$restator.store.defaultApiDefinitionEndpointUrl;
        },
        onKeyUp(e) {
            if (e.code === "Enter") {
                this.onMountEndpoint();
            }
        },
    },
}
</script>

<style scoped>
.btn.disabled:hover {
    cursor: not-allowed;
}

#r-app-loading {
    width: 100%;
    height: 100vh;
    background-color: black;
    background-repeat: no-repeat;
    background-position: center 30%;
}

@media (orientation: portrait) {
    #r-app-loading {
        background-position: center 10%;
    }
}

#r-app-loading h1 {
    font-family: monospace;
    font-size: 3vmin !important;
    line-height: 4vmin !important;
    padding-top: 3vmin;
    color: #f1f1f1;
    text-shadow:
        #000 -1.5px -1.5px 0,
        #000 1.5px -1.5px 0,
        #000 -1.5px 1.5px 0,
        #000 1.5px 1.5px 0,
        #000 -1.5px 0 0,
        #000 1.5px 0 0,
        #000 0 1.5px 0,
        #000 0 -1.5px 0;
}

#r-app-loading .r-loading-dots:after {
    content: ' .';
    animation: loading-dots 1.6s steps(5, end) infinite;}

@keyframes loading-dots {
    0%, 20% {
        color: rgba(0,0,0,0);
        text-shadow:
            .25em 0 0 rgba(0,0,0,0),
            .5em 0 0 rgba(0,0,0,0);
    }
    40% {
        color: white;
        text-shadow:
            .25em 0 0 rgba(0,0,0,0),
            .5em 0 0 rgba(0,0,0,0);
    }
    60% {
        text-shadow:
            .25em 0 0 white,
            .5em 0 0 rgba(0,0,0,0);
    }
    80%, 100% {
        text-shadow:
            .25em 0 0 white,
            .5em 0 0 white;
    }
}

@media (max-width: 720px) {
    /deep/small {
        font-size: 2.2vmin !important;
    }
}

.blockquote {
    margin-bottom: 5vmin !important;
    font-size: 2.2vmin !important;
    color: #6c757d !important;
}

.blockquote-footer {
    color: #f1f1f1 !important;
}
</style>
