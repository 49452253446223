const CONSTS = Object.freeze({
    TYPES: {
        COLLECTION: 'collection',
        DOCUMENT: 'document',
    },
    METHODS: {
        GET: 'get',
        POST: 'post',
        PUT: 'put',
        DELETE: 'delete',
        OPTIONS: 'options',
    },
    ACTIONS: {
        SHOW: 'show',
        ADD: 'add',
        EDIT: 'edit',
        DELETE: 'delete',
        OPTIONS: 'options',
    },
});

const Endpoint = function (endpointPath, method) {
    this.path = endpointPath;
    this.method = method;
    this.collectionName = endpointPath.split("/")[1];

    this.type = CONSTS.TYPES.DOCUMENT;
    if ('/' + this.collectionName === endpointPath) {
        this.type = CONSTS.TYPES.COLLECTION;
    }

    this.action = CONSTS.ACTIONS.SHOW;
    switch (method) {
        case CONSTS.METHODS.GET:
            this.action = CONSTS.ACTIONS.SHOW;
            break;
        case CONSTS.METHODS.POST:
            this.action = CONSTS.ACTIONS.ADD;
            break;
        case CONSTS.METHODS.PUT:
            this.action = CONSTS.ACTIONS.EDIT;
            break;
        case CONSTS.METHODS.DELETE:
            this.action = CONSTS.ACTIONS.DELETE;
            break;
        case CONSTS.METHODS.OPTIONS:
            this.action = CONSTS.ACTIONS.OPTIONS;
            break;
    }
    this.responses = {};
    this.request = null;

    this.addResponse = function(statusCode, response) {
        this.responses[statusCode] = response;
    };

    this.setRequest = function(request) {
        this.request = request;
    };
};

export {CONSTS, Endpoint};