<template>
    <div id="r-debug">
        <div class="r-debug-item">
            <button @click="isDebugShown = !isDebugShown" class="btn btn-secondary btn-sm btn-block">💡</button>
        </div>

        <div v-if="isDebugShown">
            <div class="r-debug-item">
                <button @click="isDebugRouteShown =! isDebugRouteShown" class="btn btn-secondary btn-sm btn-block">$route</button>
                <pre v-if="isDebugRouteShown">{{ $route }}</pre>
            </div>
            <div class="r-debug-item">
                <button @click="printData()" class="btn btn-secondary btn-sm btn-block">Print data</button>
            </div>
            <div class="r-debug-item">
                <button @click="isDebugShown = !isDebugShown" class="btn btn-secondary btn-sm btn-block">💡</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "RDebug.vue",
    data() {
        return {
            isDebugShown: false,
            isDebugRouteShown: true,
        };
    },
    methods: {
        printData() {
            console.log("-------------------------------------");
            console.log("CONSTS:", this.$restator.CONSTS);
            console.log("TYPES:", this.$restator.getTypes());
            console.log("ENDPOINTS:", this.$restator.getEndpoints());
            console.log("ROUTE:", this.$route);
            console.log("ROUTES:", this.$router.getAllRoutes());
            console.log("STORE:", this.$restator.store);
            console.log("REFS:", this.$refs);
            console.log("-------------------------------------");
        },
    },
}
</script>

<style scoped>
pre::-webkit-scrollbar {
    width: 14px;
}

pre::-webkit-scrollbar-track {
    background: #333;
}

pre::-webkit-scrollbar-thumb {
    background-color: #555 ;
    border-radius: 6px;
    border: 3px solid #333;
}

#r-debug {
    position: fixed;
    z-index: 30;
    bottom: 0;
    right: 0;
}

.r-debug-item {
    margin-top: 2px;
}

.r-debug-item pre {
    height: 50vh;
    margin-bottom: 0;
    color: white;
    background-color: #494b4f;
    overflow-y: scroll
}
</style>