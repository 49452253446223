const Response = function(statusCode) {
    this.statusCode = statusCode;

    this.setMediaType = function(mediaType) {
        this.mediaType = mediaType;
    };

    this.setDataTypeName = function(dataTypeName) {
        this.dataTypeName = dataTypeName;
    };
};

export default Response;