<template>
    <div :class="{'as-modal' : asModal}">
        <r-breadcrumb v-if="!asModal"></r-breadcrumb>

        <div id="r-resource-data">
            <template>
                <r-collection
                    v-if="resourceMeta.type === $restator.CONSTS.TYPES.COLLECTION && resourceMeta.action !== $restator.CONSTS.ACTIONS.ADD"
                    :resource-path="resourcePath"
                    :as-modal="asModal">
                </r-collection>
                <r-document-form
                    v-else-if="(resourceMeta.type === $restator.CONSTS.TYPES.COLLECTION && resourceMeta.action === $restator.CONSTS.ACTIONS.ADD) || (resourceMeta.type === $restator.CONSTS.TYPES.DOCUMENT && [$restator.CONSTS.ACTIONS.ADD, $restator.CONSTS.ACTIONS.SHOW, $restator.CONSTS.ACTIONS.EDIT, $restator.CONSTS.ACTIONS.DELETE].indexOf(resourceMeta.action) !== -1)"
                    :resource-path="resourcePath"
                    v-on:showModal="showModal"
                    :modal-selected="refreshResourceKey">
                </r-document-form>
                <div v-else>We're sorry but there is nothing to show :/</div>
            </template>
        </div>

        <!-- @TODO - move modal into DocumentForm as its used only there -->
        <b-modal ref="r-modal" scrollable :title="modalCollectionPath" size="xl" v-on:confirm="alert('ok')">
            <!-- @TODO - use same breadcrumb as in RResource.vue -> breadcrumb needs to be separated -->
            <!--<template #modal-header>-->
            <!--    HEADER-->
            <!--</template>-->

            <template #default>
                <r-resource-modal :as-modal="true" :resource-meta="modalResourceMeta" :resource-path="modalCollectionPath"></r-resource-modal>
            </template>

            <template #modal-footer="{ cancel, close }">
                <b-button size="sm" variant="secondary" @click="cancel()">
                    Cancel
                </b-button>
                <b-button
                    v-if="$route.meta.action !== $restator.CONSTS.ACTIONS.SHOW"
                    @click="onConfirm(); close()"
                    size="sm" variant="primary">
                    Confirm
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import RCollection from './RCollection.vue'
import RDocumentForm from './RDocumentForm.vue'
import RBreadcrumb from './RBreadcrumb.vue'

export default {
    name: "RResource.vue",
    components: {
        RBreadcrumb,
        RCollection,
        RDocumentForm,
        RResourceModal: () => import('./RResource.vue'),
    },
    props: {
        resourceMeta: Object,
        resourcePath: String,
        asModal: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        modalSelectedIds:{
            get() { return this.$store.state.modalSelectedIds; },
            set(value) { this.$store.commit('setModalSelectedIds', value) }
        },
    },
    watch:{
        $route (to){
            this.init(to.path);
        }
    },
    created() {
        this.init(this.resourcePath);
    },
    data() {
        return {
            refreshResourceKey: 0,

            modalCollectionPath: '',
            modalResourceMeta: {},
        }
    },
    methods: {
        onConfirm() {
            let endpoint = this.$router.match(this.modalCollectionPath + '/' + this.modalSelectedIds[0]).meta;
            this.$restator.getDocument(endpoint.collectionName, this.modalSelectedIds[0])
                .then(() => {
                    this.refreshResourceKey += 1;
                })
                .catch(error => {
                    this.$root.$emit('showToast', error.title, error.message, error.style); // @TODO - error.style should be selected here not in toast builder (simulation: replace "res.data" above with "res.res.data")
                });
        },
        showModal(collectionPath, id) {
            this.modalCollectionPath = collectionPath;
            this.modalResourceMeta = this.$router.match(collectionPath).meta;
            this.modalSelectedIds = [id];
            this.$refs['r-modal'].show()
        },
        init(resourcePath) { // @TODO - rename resourcePath
            let endpoint = this.$router.match(resourcePath).meta;

            if (endpoint.path === '/') {
                this.$router.push(this.$router.getAllRoutes()[1].path);
            }
        },
        isFieldToForeignTable(field) {
            let dataTypeName = this.$restator.getDataTypeName(this.$route.meta, 200); // @TODO - 200 based on response and also dataTypeName
            return typeof this.$restator.getType(dataTypeName).properties[field]['x-foreignTable'] !== 'undefined';
        },
        getResourcePath(resourceName, resourceId = null) {
            let resourcePath = "/" + resourceName;
            if (resourceId) {
                resourcePath += "/" + resourceId;
            }
            return resourcePath;
        },
        getResourceData(resourceName, resourceId = null) { // @TODO - seems like not used
            return this.$restator.store.resources[this.getResourcePath(resourceName, resourceId)];
        },
        getForeignTableColumnValue(field, resourceId) { // @TODO - seems like not used
            let dataTypeName = this.$restator.getDataTypeName(this.$route.meta, 200); // @TODO - 200 based on response and also dataTypeName
            let resourceName = this.$restator.getType(dataTypeName).properties[field]['x-foreignTable'];
            let resourceField = this.$restator.getType(dataTypeName).properties[field]['x-foreignColumnShow'];
            return this.getResourceData(resourceName, resourceId)[resourceField];
        },
        getForeignTablePath(field, resourceId) { // @TODO - seems like not used
            let dataTypeName = this.$restator.getDataTypeName(this.$route.meta, 200); // @TODO - 200 based on response and also dataTypeName
            let resourceName = this.$restator.getType(dataTypeName).properties[field]['x-foreignTable'];
            return this.getResourcePath(resourceName, resourceId);
        },
    },
}
</script>

<style scoped>
#r-resource-data {
    margin-top: calc(2rem + 28px);
}

.as-modal #r-resource-data {
    margin-top: 0;
}
</style>