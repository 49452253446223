import axios from 'axios';
import Response from './data_types/response.js'
import Request from './data_types/request.js'
import {CONSTS, Endpoint} from './data_types/endpoint.js'

let plugins = {};

let store = {
    // Control vars
    appEnv: 'prod', // @TODO - use constant instead of string
    isApiDefinitionReady: false,
    useDefaultApiDefinitionEndpointUrl: false,
    defaultApiDefinitionEndpointUrl: '',

    // Data from API
    resources: [],
};

let funcs = {
    // API vars & definitions
    _baseUri: null,
    _types: [], // @TODO - rename to _dataTypes
    _resources: {}, // @TODO - it should be implement once Restator will take care about the resources
    _endpoints: {},

    _useHttpAuth: false,
    _httpUser: "",
    _httpPass: "",

    setHttpAuth(httpUser, httpPass) {
        if (httpUser !== "" && httpPass !== "") {
            this._useHttpAuth = true;
            this._httpUser = httpUser;
            this._httpPass = httpPass;
        }
    },
    init(apiDefinitionEndpointUrl) {
        return new Promise((resolve, reject) => {
            const wap = require('webapi-parser').WebApiParser;
            this.ramlApiCall(apiDefinitionEndpointUrl)
                .then((res) => {
                    // Parse RAML 1.0 file
                    wap.raml10.parse(res.data)
                        .then(model => {
                            let api = model.encodes;
                            this._baseUri = api.servers[0].url.value();

                            this.initDataTypes(model);
                            this.initEndpoints(api);
                            this.initRoutes();

                            store.isApiDefinitionReady = true;
                            resolve();
                        })
                        .catch(error => {
                            reject({message: error.tt, error: error})
                        });
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    ramlApiCall(apiDefinitionEndpointUrl) {
        let config = {};
        config.baseURL = apiDefinitionEndpointUrl;
        if (this._useHttpAuth) {
            config.auth = {
                username: this._httpUser,
                password: this._httpPass,
            };
        }
        axios.interceptors.response.use((response) => response, (error) => {
            // @TODO - take a look into this error handling if it can be implemented in better way
            if (typeof error.response === 'undefined') {
                error.response = {
                    status: "",
                    statusText: error.message
                };
                error.message = 'This could be a CORS issue or an interrupted internet connection. See the console for more information.';
            }
            return Promise.reject(error)
        });
        return axios.request(config)
    },
    initDataTypes(model) {
        for (var i = 0; i < model.declares.length; i++) {
            if (typeof model.declares[i].toJsonSchema !== 'undefined') {
                this._types[model.declares[i].name.value()] = JSON.parse(model.declares[i].toJsonSchema).definitions[model.declares[i].name.value()];
            }
        }
    },
    addEndpoint(endpointPath, operationMethod) {
        return this._endpoints[endpointPath + '-' + operationMethod] = new Endpoint(endpointPath, operationMethod);
    },
    getEndpoint(endpointPath, operationMethod) {
        return this._endpoints[endpointPath + '-' + operationMethod];
    },
    initEndpoints(api) {
        // @TODO - move each for loop into related object (Collection, Operation, Response, etc.) definition file as a function
        for (const endpointConf of Object.values(api.endPoints)) {
            for (const operationConf of Object.values(endpointConf.operations)) {
                let operationMethod = operationConf.method.value();
                let endpoint = this.addEndpoint(endpointConf.path.value(), operationMethod);

                if (operationMethod === CONSTS.METHODS.GET && operationConf.responses.length > 0) {
                    for (const responseConf of Object.values(operationConf.responses)) {
                        let response = new Response(responseConf.statusCode.value());

                        if (responseConf.payloads.length > 0) {
                            // @TODO - Update the code to reflect fact that RAML can define more payloads
                            let payloadConf = responseConf.payloads[0];
                            response.setMediaType(payloadConf.mediaType.value());

                            if (payloadConf.schema.inherits.length > 0) {
                                response.setDataTypeName(payloadConf.schema.inherits[0].linkTarget.name.value());
                            }
                        }
                        endpoint.addResponse(response.statusCode, response);
                    }
                }

                if ((operationMethod === CONSTS.METHODS.POST || operationMethod === CONSTS.METHODS.PUT) && operationConf.request.payloads.length > 0) {
                    // @TODO - Update the code to reflect fact that RAML can define more payloads
                    let payloadConf = operationConf.request.payloads[0];
                    let request = new Request(payloadConf.mediaType.value());
                    if (payloadConf.schema.inherits.length > 0) {
                        request.setDataTypeName(payloadConf.schema.inherits[0].linkTarget.name.value());
                    }
                    endpoint.setRequest(request);
                }
            }
        }
    },
    getDataTypeName(endpoint, statusCode = null) {
        let dataTypeName = null;
        if (endpoint.request !== null) {
            dataTypeName = endpoint.request.dataTypeName;
        } else if(typeof endpoint.responses[statusCode] !== 'undefined') {
            dataTypeName = endpoint.responses[statusCode].dataTypeName;
        }
        return dataTypeName;
    },
    initRoutes() {
        const regex = /\/([a-zA-Z0-9_-]+)\/\{([a-zA-Z0-9_-]+)\}/;
        const substitution = "/$1/:$2";
        let routesConf = [];
        for (const endpoint of Object.values(this._endpoints)) {
            let routeResourceMeta = JSON.parse(JSON.stringify(endpoint));

            let name = endpoint.path;
            let path = endpoint.path;
            if (endpoint.type === CONSTS.TYPES.DOCUMENT) {
                name = name.replace(regex, substitution);
                path = name.replace(regex, substitution);
            }

            if (endpoint.action !== CONSTS.ACTIONS.SHOW) {
                name += '/' + endpoint.action;
                path += '/' + endpoint.action;
            }

            routesConf.push({
                name: name,
                path: path,
                meta: routeResourceMeta
            });
        }
        // @TODO - avoid from adding already existing route
        plugins.$router.addRoutes(routesConf);
    },
    getTypes() { // @TODO - rename to getDataTypes
        return this._types;
    },
    getType(dataTypeName) { // @TODO - rename to getDataType
        return this._types[dataTypeName];
    },
    getEndpoints() {
        return this._endpoints;
    },
    isJson(item) {
        item = typeof item !== "string"
            ? JSON.stringify(item)
            : item;

        try {
            item = JSON.parse(item);
        } catch (e) {
            return false;
        }

        return typeof item === "object" && item !== null;
    },
    evaluateBoolean(value) {
        switch (typeof value) {
            case 'string':
                if (value === "true" || value === "1") {
                    return true;
                }
                break;
            case 'boolean':
                return value;
        }
        return false;
    },
    collectForeignResources(routeMeta, data) {
        let foreignResources = {};
        let dataTypeName = this.getDataTypeName(routeMeta, 200); // @TODO - 200
        for (const [propertyName, propertyConf] of Object.entries(this._types[dataTypeName].properties)) {
            if (Object.prototype.hasOwnProperty.call(propertyConf, 'x-foreignTable')) {
                foreignResources[propertyName] = {
                    entriesToFetch: [],
                    foreignTable: propertyConf['x-foreignTable'],
                    foreignColumn: propertyConf['x-foreignColumn'],
                    foreignColumnShow: propertyConf['x-foreignColumnShow'],
                };

                if (routeMeta.type === CONSTS.TYPES.COLLECTION) {
                    foreignResources[propertyName].entriesToFetch = [...new Set(data.map(item => item[propertyName]))]
                } else {
                    foreignResources[propertyName].entriesToFetch.push(data[propertyName]);
                }
            }
        }

        return foreignResources;
    },
    buildApiCallToast(method, path, res, errMessage = false) {
        let title = method.toUpperCase() + ' ' + path;
        if (typeof res !== 'undefined') {
            title += ' ' + res.status + ' ' + res.statusText;
        }
        let message = '(Note: message for method ' + method.toUpperCase() + 'is not set.)';
        let style = 'default';
        if (errMessage) {
            message = errMessage;
            if (typeof res !== 'undefined' &&typeof res.data !== 'undefined' && typeof res.data.error !== 'undefined') {
                message += ' ' + res.data.error;
            }
            style = 'danger';
        } else {
            switch (method) {
                case CONSTS.METHODS.POST:
                    message = 'Newly created item with ID: ' + res.data.id;
                    break;
                case CONSTS.METHODS.PUT:
                    message = 'Item was updated';
                    break;
                case CONSTS.METHODS.DELETE:
                    message = 'Item was deleted';
                    break;
            }
            style = 'success';
        }

        return [title, message, style];
    },
    // @TODO - probably not needed
    buildApiCallToastNew(method, path, res, errMessage = false) {
        let [title, message, style] = this.buildApiCallToast(method, path, res, errMessage);
        return {title: title, message: message, style: style};
    },
    apiCall(config) {
        config.baseURL = this._baseUri;
        if (this._useHttpAuth) {
            config.auth = {
                username: this._httpUser,
                password: this._httpPass,
            };
        }
        axios.interceptors.response.use((response) => response, (error) => {
            if (typeof error.response === 'undefined') {
                error.response = {
                    status: "",
                    statusText: error.message
                };
                error.message = 'This could be a CORS issue or an interrupted internet connection. See the console for more information.';
            }
            return Promise.reject(error)
        });
        return axios.request(config)
    },
    getCollection(collectionName, params = {}, includeForeign = false) {
        let collectionPath = '/' + collectionName;
        let config = {
            url: collectionPath,
            method: CONSTS.METHODS.GET,
            params: params,
        };
        return new Promise((resolve, reject) => {
            this.apiCall(config)
                .then((res) => {

                    this.store.resources[collectionPath] = res.data;

                    let [title, message, style] = this.buildApiCallToast(CONSTS.METHODS.GET, collectionPath, res);
                    // @TODO - better to return object assigned to res like res.restator = / and then access it outside, however lets think what user really need in response (probably just status, status text, headers and data + mine toast)
                    res.title = title
                    res.message = message
                    res.style = style
                    if (!includeForeign) {
                        resolve(res);
                    }
                })
                .catch(error => {
                    reject(this.buildApiCallToastNew(CONSTS.METHODS.GET, collectionPath, error.response, error.message));
                });
        });
    },
    addDocument(document, collectionName) {
        let collectionPath = '/' + collectionName;
        let config = {
            url: collectionPath,
            method: CONSTS.METHODS.POST,
            headers: {
                'Content-Type': 'application/json',
            },
            data: document,
        };
        return new Promise((resolve, reject) => {
            this.apiCall(config)
                .then((res) => {
                    resolve(this.buildApiCallToastNew(CONSTS.METHODS.POST, collectionPath, res));
                })
                .catch(error => {
                    reject(this.buildApiCallToastNew(CONSTS.METHODS.POST, collectionPath, error.response, error.message));
                });
        });
    },
    getDocument(collectionName, documentId, includeForeign = false) {
        let documentPath = '/' + collectionName + '/' + documentId;
        let config = {
            url: documentPath,
            method: CONSTS.METHODS.GET,
        };
        return new Promise((resolve, reject) => {
            this.apiCall(config)
                .then((res) => {
                    this.store.resources[documentPath] = res.data;
                    let [title, message, style] = this.buildApiCallToast(CONSTS.METHODS.GET, documentPath, res);
                    // @TODO - better to return object assigned to res like res.restator = / and then access it outside, however lets think what user really need in response (probably just status, status text, headers and data + mine toast)
                    res.title = title
                    res.message = message
                    res.style = style
                    if (!includeForeign) {
                        resolve(res);
                    }
                })
                .catch(error => {
                    reject(this.buildApiCallToastNew(CONSTS.METHODS.GET, documentPath, error.response, error.message));
                });
        });
    },
    setDocument(document, collectionName, documentId) {
        let documentPath = '/' + collectionName + '/' + documentId;
        let config = {
            url: documentPath,
            method: CONSTS.METHODS.PUT,
            headers: {
                'Content-Type': 'application/json',
            },
            data: document,
        };
        return new Promise((resolve, reject) => {
            this.apiCall(config)
                .then((res) => {
                    resolve(this.buildApiCallToastNew(CONSTS.METHODS.PUT, documentPath, res));
                })
                .catch(error => {
                    reject(this.buildApiCallToastNew(CONSTS.METHODS.PUT, documentPath, error.response, error.message));
                });
        });
    },
    deleteDocument(collectionName, documentId) {
        let documentPath = '/' + collectionName + '/' + documentId;
        let config = {
            url: documentPath,
            method: CONSTS.METHODS.DELETE,
        };
        return new Promise((resolve, reject) => {
            this.apiCall(config)
                .then((res) => {

                    // @TODO - make it sense that we can have in .$resource "[posts/1]" and "[posts][1]"? Maybe it should be just "[posts][1]"
                    if (typeof this.store.resources['/' + collectionName] !== 'undefined') {
                        this.store.resources['/' + collectionName] = this.store.resources['/' + collectionName].filter(function (obj) {
                            return obj.id !== documentId;
                        });
                    }
                    if (typeof this.store.resources[documentPath] !== 'undefined') {
                        delete this.store.resources[documentPath];
                    }

                    resolve(this.buildApiCallToastNew(CONSTS.METHODS.DELETE, documentPath, res));
                })
                .catch(error => {
                    reject(this.buildApiCallToastNew(CONSTS.METHODS.DELETE, documentPath, error.response, error.message));
                });
        });
    },
};

export default {
    CONSTS,
    plugins,
    store,
    funcs,
};